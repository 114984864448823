import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
export const REACT_APP_STRIPE_API_KEY = "pk_test_51Ib143SI68JYG5nMT4vJt6s74N8bOoTUEFetf5dUYzxbDBJaKBr5SjBt2lJ06mwsweQtckNu9IWkOXA7UZwjyyF800LTTVHQld"

/* START -------------------------------------------------------------- Set Storage Data */

export function saveTokens(params: { token: any; expires_in: any; }) {
  const { token, expires_in } = params;
  const expires_at = new Date();
  expires_at.setSeconds(expires_at.getSeconds() + expires_in);
  setLocalState("expires_in", expires_in);
  setLocalState("expires_at", expires_at.getTime());
  setLocalState("token", token);
}

export const setLocalState = (key: string, val: any) => {
  try {
    localStorage.setItem(key, val);
  } catch (err) {
    // Ignore write errors.
  }
};

export const getLocalState = (key: string, isToken = false) => {
  try {
    const serializedState = isToken ? localStorage.getItem(key) : JSON.parse(localStorage.getItem(key)!);
    if (serializedState === null) {
      return null;
    }
    return serializedState;
  } catch (err) {
    return null;
  }
};

/* END -------------------------------------------------------------- Set Storage Data */

/* START -------------------------------------------------------------- Currency Converter */
export const onCurrencyConverter = (totalPayment: any) => {
  let brandSetting = getLocalState('brandSetting');
  if (brandSetting?.ExtraFields?.currency_type === "INR") {
    return `₹ ${parseFloat(totalPayment ?? 0).toFixed(2)}`
  }
  if (brandSetting?.ExtraFields?.currency_type === "$") {
    return `$ ${parseFloat(totalPayment ?? 0).toFixed(2)}`
  }
  return `£ ${parseFloat(totalPayment ?? 0).toFixed(2)}`
}
/* END -------------------------------------------------------------- Currency Converter */

/* START -------------------------------------------------------------- is Login */
export const isLogin = () => {
  const _expiresAt = getLocalState("expires_at");
  const access_token = getLocalState("token", true);
  const userData = getLocalState('userData');
  if (_expiresAt && userData && access_token && new Date().getTime() < _expiresAt) {
    return true;
  } else {
    // localStorage.clear();
    return false;
  }
}

/* END -------------------------------------------------------------- is Login */

/* START -------------------------------------------------------------- API interceptor */
export const apiCall = async (data: any) => {
  const { contentType, method, endPoint, body } = data;
  const token = await getLocalState("token", true) || "";
  const header = {
      "Content-Type": contentType,
      token
  };
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  body &&
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
      );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
/* END -------------------------------------------------------------- API interceptor */
