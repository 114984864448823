import { lazy } from "react";
import AudioCallWeb from "../../../AudioCall/src/AudioCall.web";

// lazy loading
// import { EmailAccountRegistrationWeb } from '../../../email-account-registration/src/EmailAccountRegistration.web'

// import { DashboardWeb } from '../../../dashboard/src/Dashboard.web';

const Login = lazy(() =>
  import("../../../email-account-registration/src/EmailAccountRegistration.web")
);

const Dashboard = lazy(() => import("../../../dashboard/src/Dashboard.web"));

const CreateNewPassword = lazy(() =>
  import("../../../forgot-password/src/CreateNewPassword.web")
);
const ForgotPassword = lazy(() =>
  import("../../../forgot-password/src/ForgotPassword.web")
);
const ForgotPasswordOTP = lazy(() =>
  import("../../../forgot-password/src/ForgotPasswordOTP.web")
);
const SearchResultWeb = lazy(() =>
  import("../../../AdvancedSearch/src/SearchResult.web")
);
const Contactus = lazy(() => import("../../../contactus/src/ContactUs.web"));

const AboutUs = lazy(() => import("../../../contactus/src/AboutUs.web"));

const ConfirmedAppointment = lazy(() =>
  import("../../../AppointmentManagement/src/ConfirmedAppointment.web")
);

const AppointmentConfirmation = lazy(() =>
  import("../../../AppointmentManagement/src/AppointmentConfirmation.web")
);
const FailedAppointment = lazy(() =>
  import("../../../AppointmentManagement/src/FailedAppointment.web")
);
const DoctorInformation = lazy(() =>
  import("../../../AppointmentManagement/src/DoctorInformation.web")
);
const HelpCenter = lazy(() =>
  import("../../../user-profile-basic/src/HelpCenter.web")
);
const MyFavDoctor = lazy(() =>
  import("../../../user-profile-basic/src/MyFavDoctor.web")
);
const UserProfile = lazy(() =>
  import("../../../user-profile-basic/src/UserProfile.web")
);
const DoctorUserProfile = lazy(() =>
  import("../../../user-profile-basic/src/DoctorUserProfile.web")
);
const VideoCallWeb = lazy(() =>
  import("../../../VideoCall5/src/VideoCall.web")
);

const routes = [
  {
    path: "/",
    auth: true,
    exact: true,
    title: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    auth: false,
    title: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    auth: false,
    title: "Forgot Password",
    component: ForgotPassword,
  },
  {
    path: "/forgot-password-otp",
    auth: false,
    title: "Forgot Password OTP",
    component: ForgotPasswordOTP,
  },
  {
    path: "/otp-verification",
    auth: false,
    title: "Otp Verification",
    component: ForgotPasswordOTP,
  },
  {
    path: "/create-new-password",
    auth: false,
    title: "Create New Password",
    component: CreateNewPassword,
  },

  {
    path: "/contact-us",
    auth: true,
    title: "Contact us",
    component: Contactus,
  },
  {
    path: "/search-result",
    auth: true,
    title: "Search Result Web",
    component: SearchResultWeb,
  },
  {
    path: "/video-call",
    auth: true,
    title: "Video Call",
    component: VideoCallWeb,
  },
  {
    path: "/audio-call",
    auth: true,
    title: "Audio Call",
    component: AudioCallWeb,
  },
  {
    path: "/about-us",
    auth: true,
    title: "About us",
    component: AboutUs,
  },
  {
    path: "/confirmed-appointment",
    auth: true,
    title: "Confirmed Appointment",
    component: ConfirmedAppointment,
  },
  {
    path: "/failed-appointment",
    auth: true,
    title: "Failed Appointment",
    component: FailedAppointment,
  },
  {
    path: "/appointment-confirmation",
    auth: true,
    title: "Appointment Confirmation",
    component: AppointmentConfirmation,
  },

  {
    path: "/doctor-information/:doctorID",
    auth: true,
    title: "Doctor Information",
    component: DoctorInformation,
  },
  {
    path: "/help-center",
    auth: true,
    title: "Help Center",
    component: HelpCenter,
  },
  {
    path: "/my-fav-doctor",
    auth: true,
    title: "My Fav Doctor",
    component: MyFavDoctor,
  },
  {
    path: "/doctor-profile",
    auth: true,
    title: "Doctor User Profile",
    component: DoctorUserProfile,
  },
  {
    path: "/user-profile",
    auth: true,
    title: "User Profile",
    component: UserProfile,
  },
];

export default routes;
