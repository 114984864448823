import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// @ts-ignore
import MicButtonImg from "../assets/mic.svg";
// @ts-ignore
import MicButtonImgOff from "../assets/micOff.svg";

const MicButton = (props: any) => {
  const { room } = props;
  const [isMicMuted, setIsMicMuted] = useState(false);

  const handleMicButtonPressed = () => {
    isMicMuted ? isUnmuteMic() : isMuteMic();
    setIsMicMuted(!isMicMuted);
  };

  const isMuteMic = () => {
    // mute our microphone so other users will be not able to hear us
    room.localParticipant.audioTracks?.forEach(
      (localAudioTrackPublication: any) => {
        localAudioTrackPublication.track.disable();
      }
    );
  };

  const isUnmuteMic = () => {
    // turn on mic back
    room.localParticipant.audioTracks?.forEach(
      (localAudioTrackPublication: any) => {
        localAudioTrackPublication.track.enable();
      }
    );
  };

  return (
    <div className="audio_button_container">
      <div className="audio_mic_button">
        <img
          data-testid="micMuteId"
          src={isMicMuted ? MicButtonImgOff : MicButtonImg}
          onClick={handleMicButtonPressed}
          className="audio_button_image"
        />
      </div>
    </div>
  );
};

export default withRouter(MicButton);
export { MicButton };
