import React from "react";
import { withRouter } from "react-router-dom";
import AudioRoom from "./AudioRoom.web";

const Audios = (props: any) => {
  const { room } = props;

  const formatTime = (time: number) =>
    `${String(Math.floor(time / 60)).padStart(2, "0")} : ${String(
      time % 60
    ).padStart(2, "0")}`;

  return (
    <div className="videos_container">
      {room && <AudioRoom {...props} formatTime={formatTime} />}
    </div>
  );
};

export default withRouter(Audios);
export { Audios };
