import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

const ConfirmModal = (props: any) => {
    const {
        title,
        textBody,
        subTextBody,
        textCancelBtn,
        textConfirmBtn,
        isModalOpen,
        isCloseModalOpen,
        handleSubmitBtn,
        onHandleEndCallUpdateStatus
    } = props;

    return (
        <>
            <Modal className="custom_modal modal_sm common_close_btn logout_modal" isOpen={isModalOpen} toggle={isCloseModalOpen} centered>
                <ModalHeader toggle={onHandleEndCallUpdateStatus}>{title}</ModalHeader>
                <ModalBody className="pt-2 pb-4">
                    <p>{textBody}</p>
                    {subTextBody && <p className="mt-3">{subTextBody}</p>}
                </ModalBody>
                <ModalFooter>
                    <Button color="transparent" onClick={onHandleEndCallUpdateStatus}>{textCancelBtn}</Button>
                    <span className="divider"></span>
                    <Button color="transparent" onClick={handleSubmitBtn}>{textConfirmBtn}</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ConfirmModal;