// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import App from '../../blocks/studio_store_medical_router/src/web/App';
import '../../blocks/studio_store_medical_router/src/web/assets/scss/main.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'bootstrap/dist/css/bootstrap.min.css';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(registration => {
    })
    .catch(err => {
    });
}

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
