import React, { useEffect, useState } from "react";
import ConfirmModal from "../../studio_store_medical_components/src/CommonComponent/ConfirmModal.web";
// @ts-ignore
import PhoneCallIcon from "../assets/phone_call.svg";
import { withRouter } from "react-router-dom";
import { getLocalState } from "../../studio_store_medical_components/src/Utility.web";
import { toast } from "react-toastify";

const configJSON = require("./config");
const baseURlConfig = require("../../../framework/src/config");

const initialStateData = {
  title: ``,
  textBody: ``,
  subTextBody: ``,
  textCancelBtn: ``,
  textConfirmBtn: ``,
};

const LeaveRoomButton = (props: any) => {
  const { room, location } = props;
  const [isOpenConfirmModal, setIsOPenConfirmModal] = useState(false);
  const [isOpenPrescriptionModal, setIsOPenPrescriptionModal] = useState(false);
  const [modalContent, setModalContent] = useState(initialStateData);
  const userData = getLocalState("userData");

  useEffect(() => {
    if (userData?.attributes.role !== "DOCTOR") {
      nonDoctorModal();
    } else {
      doctorModal();
    }
  }, []);

  //open confirm popup on clicking end appointment if the role is not doctor
  const nonDoctorModal = () => {
    setModalContent({
      title: `End Appointment?`,
      textBody: `Are you sure you'd like to end the virtual visit?`,
      subTextBody: `You will no longer be able to communicate with this doctor via video or audio.`,
      textCancelBtn: `No`,
      textConfirmBtn: `Yes`,
    });
  };

  //open confirm popup on clicking end appointment if the role is doctor
  const doctorModal = () => {
    setModalContent({
      title: `End Appointment?`,
      textBody: `Are you sure you'd like to end the virtual visit?`,
      subTextBody: `You will no longer be able to communicate with this patient via video or audio. You will still be able to send Prescription to the patient.`,
      textCancelBtn: `No`,
      textConfirmBtn: `Yes`,
    });
  };

  // on clicking end appointment button
  const handleRoomDisconnection = () => {
    setIsOPenConfirmModal(true);
  };

  // close confirm popup
  const onHandleCloseConfirmModal = () => {
    setIsOPenConfirmModal(false);
  };

  const onHandleEndCallUpdateStatus = () => {
    if (isOpenPrescriptionModal) {
      room.disconnect();
      const token = getLocalState("token", true);
      fetch(
        `${baseURlConfig?.baseURL}/${configJSON.noPrescriptionApiEndpoint}`,
        {
          method: configJSON.apiPostMethod,
          headers: {
            "Content-Type": configJSON.contentType,
            token,
          },
          body: JSON.stringify({
            patient_booking_id: location?.state?.patientBookingID,
          }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          toast.success(res?.message);
          setTimeout(() => {
            window.location.href = `/doctor-profile`;
          }, 1500);
        });
    } else {
      setIsOPenConfirmModal(false);
    }
  };

  const handleSubmitBtn = () => {
    if (userData?.attributes.role !== "DOCTOR") {
      setIsOPenConfirmModal(false);
      room.disconnect();
      window.location.href = "/user-profile";
    } else if (isOpenPrescriptionModal) {
      setIsOPenConfirmModal(false);
      room.disconnect();
      window.location.href = `/doctor-profile?isShowPrescription=${true}&bookingId=${
        props?.location?.state?.patientBookingID
      }`;
    } else {
      setIsOPenConfirmModal(false);
      setTimeout(() => {
        setModalContent({
          title: `Prescription`,
          textBody: `Does the patient require a prescription?`,
          subTextBody: ``,
          textCancelBtn: `No`,
          textConfirmBtn: `Yes`,
        });
        setIsOPenConfirmModal(true);
        setIsOPenPrescriptionModal(true);
      }, 800);
    }
  };

  return (
    <>
      <div className="audio_button_container">
        <button
          data-testid="disconnectRoomId"
          className="audio_button_end"
          onClick={handleRoomDisconnection}
        >
          <img src={PhoneCallIcon} alt="PhoneCallIcon" />
        </button>
      </div>
      {isOpenConfirmModal && (
        <ConfirmModal
          data-testid="confirmModalId"
          title={modalContent?.title}
          textBody={modalContent?.textBody}
          subTextBody={modalContent?.subTextBody}
          textCancelBtn={modalContent?.textCancelBtn}
          textConfirmBtn={modalContent?.textConfirmBtn}
          isModalOpen={isOpenConfirmModal}
          isCloseModalOpen={onHandleCloseConfirmModal}
          handleSubmitBtn={handleSubmitBtn}
          onHandleEndCallUpdateStatus={onHandleEndCallUpdateStatus}
        />
      )}
    </>
  );
};

export default withRouter(LeaveRoomButton);
export { LeaveRoomButton };
