import React from 'react';
import "./waiting-loader.css";

export const WaitingLoader = () => {
  return (
    <>
      <div className="lds-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </>
  );
};
