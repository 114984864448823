import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import './DownloadApps.scss';
// @ts-ignore
import isEmpty from "lodash/isEmpty";
import { Link } from 'react-router-dom';
import { setTimeout } from 'timers';
import { getLocalState } from '../Utility.web';
export const Screen1 = require("./images/screen1.png");
export const Screen2 = require("./images/screen2.png");
export const GooglePlay = require("./images/google_play.png");
export const PlayStore = require("./images/app_store.png");

const DownloadApps = () => {
    const [update, setUpdate] = useState<any>([]);
    if (isEmpty(update)) {
        const id = setInterval(() => {
            let check = getLocalState("webBannerList");
            if (check !== null && check !== undefined) {
                let data = getLocalState("webBannerList");
                setUpdate(data)
                clearInterval(id)
            }
        }, 500)
    }

    return (
        <section className="apps_sec">
            {/* {!isEmpty(update) ?
                <Link to={update[2]?.attributes?.images?.data[0]?.attributes?.url_link}>
                    <img
                        src={update[2]?.attributes?.images?.data[0]?.attributes?.url}
                        alt="Slider"
                        className="img-fluid"
                    />
                </Link>
                :
                <div className="text-center banner_loader"><i className="fa fa-spinner fa-pulse fa-2x"></i></div>
            } */}
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="content_view">
                            <h1>Download the App <br className="d-none d-md-block" /> for Free</h1>
                            <div className="apps">
                                <a href="https://play.google.com/store/games"><img src={GooglePlay} alt="GooglePlay" className="img-fluid" /></a>
                                <a href="https://play.google.com/store/games"><img src={PlayStore} alt="PlayStore" className="img-fluid" /></a>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="ss_view">
                            <img src={Screen2} alt="image" className="img-fluid screen2" />
                            <img src={Screen1} alt="image" className="img-fluid screen1" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default DownloadApps;
