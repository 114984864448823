import React from "react";
import { Route, Redirect } from "react-router-dom";
import DownloadApps from "../../../studio_store_medical_components/src/DownloadApps/DownloadApps.web";
import { isLogin } from "../../../studio_store_medical_components/src/Utility.web";

const PrivateRoute = ({
  component: Component,
  ...rest
}: any) => {
  let isAuthenticated = isLogin();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <>
            <Component {...props} {...rest} title={rest.title} />
            <DownloadApps />
          </>
        ) : (
          <Redirect
            to={'/login'}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
