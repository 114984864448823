import React from "react";
import { withRouter } from "react-router-dom";
import AudioCallController from "./AudioCallController.web";
// @ts-ignore
import Audios from "./Audio.web";
import AudioButtons from "./AudioButton.web";

import "./audio-call.scss";
import { Modal } from "reactstrap";
class AudioCallWeb extends AudioCallController {
  render() {
    const { room } = this.state;
    return (
      <Modal isOpen={true} modalClassName="fullscreen-modal">
        <div className="audio_section_container">
          {room && (
            <>
              <Audios room={room} {...this.props} />
              <AudioButtons room={room} {...this.props} />
            </>
          )}
        </div>
      </Modal>
    );
  }
}
// @ts-ignore
export default withRouter(AudioCallWeb);
export { AudioCallWeb };
