import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Label, UncontrolledDropdown } from "reactstrap";
import Select from 'react-select';
import { defaultTheme } from "react-select";
// @ts-ignore
import isEmpty from "lodash/isEmpty";
import WebHeaderController, { Props } from "./WebHeaderController.web";
import "./WebHeader.scss";

const { colors } = defaultTheme;

export const Logo = require("./images/logo.png");
export const CallUs = require("./images/call_home.png");
export const MapMarker = require("./images/marker_icon.svg");
export const SearchIcon = require("./images/search_icon.svg");
export const CrossIcon = require("./images/cross_circle_icon.svg");
export const NoResult = require("./images/no_result.png");
export const ProfilePic = require("./images/profile.png");

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { getLocalState, isLogin } from '../Utility.web';

// styled components

const Menu = (props: any) => {
    return (
        <div className="menu" {...props} />
    );
};

const Blanket = (props: any) => (
    <div
        style={{
            bottom: 0,
            left: 0,
            top: 0,
            right: 0,
            position: "fixed",
            zIndex: 1
        }}
        {...props}
    />
);
//   @ts-ignore
const Dropdown = ({ children, isOpen, target, onClose }) => (
    <div className="drop_down_city">
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);

const Svg = (p: any) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        focusable="false"
        role="presentation"
        {...p}
    />
);

const DropdownIndicator = () => (
    <Svg>
        <path
            d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
            fillRule="evenodd"
            fill="#7C8188"
        />
    </Svg>
);

class WebHeader extends WebHeaderController {
    render() {
        const { userPicData, searchText, searchOptions } = this.state;
        const userData = getLocalState("userData");
        const brandSetting = getLocalState("brandSetting");
        const value = searchText;
        const isAuth = isLogin();
        return (
            <header className="header" >
                <Navbar color="white" light expand="lg">
                    <Container>
                        <NavbarBrand href={userData?.attributes?.role == "DOCTOR" ? "/doctor-profile" : "/"}>
                            {brandSetting?.commonLogoSrc ?
                                <div className="logo">
                                    <img src={brandSetting?.commonLogoSrc} alt="logo" className="img-fluid" />
                                </div>
                                :
                                brandSetting?.ExtraFields?.heading
                            }
                        </NavbarBrand>

                        <NavbarToggler onClick={this.toggle} />

                        {isEmpty(userData) && (
                            <Nav className="ml-lg-auto mobile_view" navbar>
                                <NavItem>
                                    <Link
                                        to={{
                                            pathname: "/login",
                                            state: { activeTab: "2" }
                                        }}
                                        className="nav-link"
                                    >
                                        Login
                                    </Link>
                                </NavItem>
                                <NavItem><span>/</span></NavItem>
                                <NavItem>
                                    <Link
                                        to={{
                                            pathname: "/login",
                                            state: {
                                                state: { activeTab: "1" }
                                            }
                                        }}
                                        className="nav-link"
                                    >
                                        Sign up
                                    </Link>
                                </NavItem>
                            </Nav>
                        )}

                        {
                            !isEmpty(userPicData) && (
                                <ul className="list-unstyled my_profile mobile_view mb-0">
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {isEmpty(userPicData?.profile_pic) && (
                                                <span className="first_letter">
                                                    {userPicData?.full_name.charAt().toUpperCase()}
                                                </span>
                                            )}
                                            {!isEmpty(userPicData) && (
                                                <img src={userPicData?.profile_pic} alt="Image" title={userPicData?.full_name} className="img-fluid" />
                                            )}
                                            {userPicData?.full_name}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem tag="div">
                                                <Link to={!isEmpty(userPicData?.role) && userPicData?.role === 'PATIENT' ? "/user-profile" : "/doctor-profile"}>My Profile</Link>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </ul>
                            )
                        }

                        <Collapse isOpen={this.state.isOpen} navbar>
                            <div className="call_us_view">
                                <img src={CallUs} alt="icon" className="img-fluid" />
                                <div className="content">
                                    <p>CALL US</p>
                                    <h6>{brandSetting?.commonTextsContent?.callUs ? brandSetting?.commonTextsContent?.callUs : "+1 123 456 1234"}</h6>
                                </div>
                            </div>

                            {isAuth && userData?.attributes?.role !== "DOCTOR" ?
                                <div className="select_search_view">
                                    {/* <div className="select_location">
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><img src={MapMarker} alt="icon" className="img-fluid" /></InputGroupText>
                                                </InputGroupAddon>
                                                <Dropdown
                                                    isOpen={this.state.isSelectOpen}
                                                    onClose={this.toggleOpen}
                                                    target={
                                                        <Button
                                                            onClick={this.toggleOpen}
                                                            isSelected={this.state.isSelectOpen}
                                                            color="transparent"
                                                        >
                                                            {this.state.value ? this.state.controlValue : "Select a City"}
                                                        </Button>
                                                    }
                                                >
                                                    <Label htmlFor="searchoptions">Search City Here
                                                        <Button color="transparent" className="p-0" onClick={this.toggleOpen}>
                                                            <img src={CrossIcon} alt="icon" className="img-fluid" />
                                                        </Button>
                                                    </Label>
                                                    <Select
                                                        inputId="searchoptions"
                                                        backspaceRemovesValue={false}
                                                        components={{
                                                            DropdownIndicator,
                                                            IndicatorSeparator: null,
                                                        }}
                                                        controlShouldRenderValue={false}
                                                        hideSelectedOptions={false}
                                                        isClearable={false}
                                                        menuIsOpen
                                                        isMulti={false}
                                                        onChange={this.onSelectChange}
                                                        options={citieslist}
                                                        placeholder="Search City"
                                                        tabSelectsValue={false}
                                                        className="common_selectpicker"
                                                        classNamePrefix="select"
                                                        //@ts-ignore
                                                        value={this.state.value}
                                                        autoFocus
                                                        maxMenuHeight={240}
                                                    />
                                                </Dropdown>
                                            </InputGroup>
                                        </div>
                                        <span className="brder"></span> */}

                                    <div className="search_input">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <img src={SearchIcon} alt="icon" className="img-fluid" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                type="text"
                                                placeholder="Search Doctor.."
                                                //@ts-ignore
                                                onChange={(event: any) => { this.handleChange(event) }}
                                                value={value}
                                                onKeyUp={(event) => { this.handleChange(event) }}
                                            />
                                        </InputGroup>

                                        {this.state.isCustomToggleOpen ?
                                            <div className="search_menu">
                                                {searchOptions?.length > 0 ?
                                                    <>
                                                        <div className="group_heading">
                                                            <span>Search Result</span>
                                                            <Button color="transparent" className="p-0" onClick={() => this.updateCustomToggle(false)}>
                                                                <img src={CrossIcon} alt="icon" className="img-fluid" />
                                                            </Button>
                                                        </div>
                                                        <ul className="list-inline">
                                                            {searchOptions?.map((item: any, i: any) => {
                                                                return (
                                                                    <li
                                                                        onClick={() => this.onClickSearchOption(item)}
                                                                        key={i}
                                                                    >{`Dr. ${item?.label}`} <span className="">{item?.specialities?.length > 1 && `(${item?.specialities})`}</span></li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </>
                                                    :
                                                    <div className="no_message">
                                                        <div className="top">
                                                            <span>Search Result</span>
                                                            <Button color="transparent" className="p-0" onClick={() => this.updateCustomToggle(false)}>
                                                                <img src={CrossIcon} alt="icon" className="img-fluid" />
                                                            </Button>
                                                        </div>
                                                        <div className="img_view">
                                                            <img src={NoResult} alt="No Result" className="img-fluid" />
                                                            <h4>No Results Found !</h4>
                                                            <span>Try modifying your search to get relevant results.</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                :
                                null
                            }


                            {/* {isEmpty(userData) && (
                                <Nav className="ml-auto desktop_view" navbar>
                                    <NavItem>
                                        <Link
                                            to={{
                                                pathname: "/login",
                                                state: { activeTab: "2" }
                                            }}
                                            className="nav-link"
                                        >
                                            Login
                                        </Link>
                                    </NavItem>
                                    <NavItem><span>/</span></NavItem>
                                    <NavItem>
                                        <Link
                                            to={{
                                                pathname: "/login",
                                                state: { activeTab: "1" }
                                            }}
                                            className="nav-link"
                                        >
                                            Sign up
                                        </Link>
                                    </NavItem>
                                </Nav>
                            )} */}

                            {!isEmpty(userPicData) && (
                                <Nav className="ml-auto my_profile desktop_view" navbar>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {isEmpty(userPicData?.profile_pic) && (
                                                <span className="first_letter">
                                                    {userPicData?.full_name.charAt().toUpperCase()}
                                                </span>
                                            )}
                                            {!isEmpty(userPicData?.profile_pic) && <img
                                                src={userPicData?.profile_pic}
                                                alt="Image"
                                                className="img-fluid"
                                                title={userPicData?.full_name}
                                            />}
                                            {userPicData?.full_name}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem tag="div">
                                                <Link to={!isEmpty(userPicData?.role) && userPicData.role === 'PATIENT' ? "/user-profile" : "/doctor-profile"}>My Profile</Link>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav>
                            )
                            }
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        )
    }
}

// @ts-ignore
export default withRouter(WebHeader);