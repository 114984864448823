import React from "react";
import { withRouter } from "react-router-dom";
import MicButton from "./MicButton.web";
import LeaveRoomButton from "./LeaveRoomButton.web";

const AudioButtons = (props: any) => {
  return (
    <div className="audio_buttons_container">
      <MicButton {...props} />
      <LeaveRoomButton {...props} />
    </div>
  );
};

export default withRouter(AudioButtons);
export { AudioButtons };
