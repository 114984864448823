import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import WebFooter from '../../../studio_store_medical_components/src/FooterWeb/FooterWeb.web';
import Loader from '../../../studio_store_medical_components/src/Loader/Loader.web';
import { getLocalState } from '../../../studio_store_medical_components/src/Utility.web';
import WebHeaderWeb from '../../../studio_store_medical_components/src/WebHeader/WebHeader.web';
import PrivateRoute from './PrivateRoute.web';
import PublicRoute from './PublicRoute.web';
import routes from './Route.web';


const Routes = () => {

  const brandSetting = getLocalState('brandSetting');
  const stripePubKey = brandSetting?.PaymentKeys?.stripe?.stripe_pub_key ? brandSetting?.PaymentKeys?.stripe?.stripe_pub_key : ''
  const stripePromise = loadStripe(stripePubKey);
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Switch>
          <Suspense
            fallback={<Loader loading={true} />}
          >
            <WebHeaderWeb />
            <div className='child-content'>
              {routes.map((route: any, index: number) => {
                if (route.auth) {
                  return (
                    <PrivateRoute
                      key={index}
                      {...route}
                    />
                  )
                } else {
                  return (
                    <PublicRoute
                      key={index}
                      {...route}
                    />
                  )
                }
              })}
            </div>
            <WebFooter />
          </Suspense>
        </Switch>
      </Router>
    </Elements>
  )
};

export default Routes;