// import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";

// @ts-ignore
import capitalize from "lodash/capitalize";
// @ts-ignore
import map from "lodash/map";
// @ts-ignore
import isEmpty from "lodash/isEmpty";
import { getLocalState } from "../Utility.web";
import _ from "lodash";


// export const configJSON = require("./config");

export interface Props {
    id: string;
    history: any;
}

export interface S {
    // Customizable Area Start
    isOpen: boolean
    isSelectOpen: boolean
    isCustomToggleOpen: boolean
    value: any;
    controlValue: string;
    userPicData: any;
    citieslist: any;
    searchResult: any;
    searchText: string;
    searchOptions: any;
    currentSelectedCity: any;
    shouldSearchDoctorMenuOpen: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;

    // Customizable Area End
}
export default class WebHeaderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCityListAPICallId: string;
    getSearchAPICallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            // Customizable Area Start
            isOpen: false,
            isSelectOpen: false,
            isCustomToggleOpen: false,
            value: [],
            controlValue: "Select City",
            userPicData: "",
            citieslist: [],
            searchResult: [],
            searchText: "",
            searchOptions: [],
            currentSelectedCity: {},
            shouldSearchDoctorMenuOpen: true,
            // Customizable Area End
        };

        // Customizable Area Start
        this.handleChange = this.handleChange.bind(this);
        this.getSearchResult = _.debounce(this.getSearchResult,500);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            // list of the city
            if (this.getCityListAPICallId === apiRequestCallId) {
                if (responseJson?.city_data) {
                    const options: any = []

                    map(responseJson.city_data, (city: any) => {
                        const cityData = city[0];
                        options.push({
                            value: cityData.name,
                            latitude: cityData.latitude,
                            longitude: cityData.longitude,
                            label: capitalize(cityData.name)
                        })
                    })

                    this.setState({ citieslist: options });
                }
            }

            // get searched result
            if (this.getSearchAPICallId === apiRequestCallId) {
                if (responseJson?.data) {
                    const searchOptions: any = [];

                    map(responseJson.data, (result: any) => {
                        const name = result?.attributes?.account?.data?.attributes?.full_name;
                        const id = result.attributes.id;
                        const accountID = result?.attributes?.account_id;
                        const specialities = Array.isArray(result?.attributes?.specialities) ? result?.attributes?.specialities.join(', ') : []

                        searchOptions.push({
                            value: name,
                            label: capitalize(name),
                            id,
                            accountID,
                            specialities
                        })
                    })
                    this.setState({
                        searchResult: responseJson.data,
                        searchOptions
                    });
                }
            }

            const currentUser = getLocalState('userData');
            if (responseJson?.data && responseJson?.data?.type === "account" && getLocalState('userData', true)) {
                this.setState({
                    userPicData: responseJson?.data?.attributes
                })
            } else {
                this.setState({
                    userPicData: currentUser?.attributes
                })
            }
        }
    }

    // async componentDidMount() {
    //     this.getCitieslist();
    // }

    //**************************Search Funtion Start**********************
    handleChange = (event: any) => {
        this.customToggle(true);
        if (event.target.value === '') {
            this.setState({ searchOptions: [], searchText: "" })
        } else {
            this.setState({ searchText: !isEmpty(event?.target) ? event.target.value : event }, this.getSearchResult)
        }
    }

    onClickSearchOption = (values: any) => {
        if (!isEmpty(values)) {
            this.props.history.push({
                pathname: `/doctor-information/${values.id}`,
                state: { accountID: values.accountID }
            })
        }
        this.setState({ searchOptions: [], searchText: "" })
        this.customToggle(false);
    }

    customToggle = (value: any) => {
        this.setState({ isCustomToggleOpen: value })
    };

    updateCustomToggle = (value: boolean) => {
        this.setState({ isCustomToggleOpen: value })
        this.setState({ searchOptions: [], searchText: "" })
    }
    //********************Search Function End**************************

    // Search API
    getSearchResult = () => {
        const { searchText, currentSelectedCity } = this.state;

        if (searchText.length > 0) {
            const apiPostMethod = "POST";
            const endPoint = "bx_block_search/searches/search";
            const token = getLocalState("token", true);
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            const headers = {
                "Content-Type": "application/json",
            };
            if (!isEmpty(token)) {
                // @ts-ignore
                headers.token = token;
            }
            let requestBody: any = {
                data: {
                    search: searchText.trim()
                }
            }

            this.getSearchAPICallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                endPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(requestBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                apiPostMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }

        // @ts-ignore
        // const currentUserLocation = JSON.parse(localStorage.getItem("location"))
        // TODO: We'll un-comment later, do not remove it.
        // if (!isEmpty(currentSelectedCity)) {
        //   requestBody.data.latitude = currentSelectedCity.latitude;
        //   requestBody.data.longitude = currentSelectedCity.longitude;
        // } else if (!isEmpty(currentUserLocation)) {
        //   requestBody.data.latitude = currentUserLocation.latitude;
        //   requestBody.data.longitude = currentUserLocation.longitude;
        // }
    }

    updateShouldSearchDoctorMenuOpen = (value: boolean) => {
        this.setState({ shouldSearchDoctorMenuOpen: value })
    }

    // onChangeSearch = (event: any, action: any) => {
    //     if (action.action == "input-change") {
    //         this.setState({ searchText: !isEmpty(event?.target) ? event.target.value : event }, this.getSearchResult)
    //     } else if (action.action == "input-blur") {
    //         this.setState({ searchText: '' }, this.getSearchResult)
    //     }
    // }

    getCitieslist = () => {
        const endpoint = "account_block/accounts/fetch_city";
        const httpsGetMethod = "GET";
        const token = getLocalState("token", true);
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        const headers = { "Content-Type": "application/json", token };

        this.getCityListAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            httpsGetMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
        this.customToggle(false);
    }

    toggleOpen = () => {
        this.setState({ isSelectOpen: !this.state.isSelectOpen })
    };

    onSelectChange = (value: any) => {
        this.toggleOpen();
        // setValue(value);
        // setControlValue(value.label)
        this.setState({
            controlValue: value.label,
            value: value,
            currentSelectedCity: value
        })
    }
}

