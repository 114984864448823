import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { isEmpty } from "../../../framework/src/Utilities";
import { connect, LocalAudioTrack, LocalDataTrack } from "twilio-video";
import {
  getLocalState,
  apiCall,
} from "../../studio_store_medical_components/src/Utility.web";
export const configJSON = require("./config");

const audioConstraints = {
  video: false,
  audio: true,
};

export interface Props {
  // Customizable Area Start
  location: any;
  history: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  call: any;
  device: any;
  room: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AudioCallController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTokenAPIAudioCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      call: null,
      device: null,
      room: "",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    const { location } = this.props;
    if (location?.state?.patientBookingID) {
      this.getTokenFromTwilioForAudio(location.state.patientBookingID);
    } else {
      this.props.history.goBack();
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // Audio Call Token
      if (this.getTokenAPIAudioCallId === apiRequestCallId) {
        if (responseJson?.errors && Array.isArray(responseJson?.errors)) {
          this.props.history.push("/");
        } else {
          const userData = getLocalState("userData");
          if (
            userData?.attributes?.role === "DOCTOR" &&
            responseJson?.access_token_doctor
          ) {
            localStorage.setItem(
              "accessToken",
              responseJson?.access_token_doctor
            );
          } else {
            localStorage.setItem(
              "accessToken",
              responseJson?.access_token_patient
            );
          }
          localStorage.setItem("roomId", responseJson.room_id);
          this.connectToRoom();
        }
      }
    }
  }

  // set Room
  setRoom = (room: any) => {
    this.setState({ room });
  };

  // Call related functions
  getTokenFromTwilioForAudio = async (patientBookingID: any = "") => {
    let requestBody = {
      patient_booking_id: patientBookingID,
    };
    this.getTokenAPIAudioCallId = await apiCall({
      contentType: configJSON.contentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.audioCallAccessTokeApiEndPoint,
      body: requestBody,
    });
  };

  connectToRoom = async (roomID: string = "") => {
    let currentRoomID: any;
    if (isEmpty(roomID)) {
      currentRoomID = getLocalState("roomId", true);
    } else {
      currentRoomID = roomID;
    }

    const accessToken: any = getLocalState("accessToken", true);
    navigator.mediaDevices
      .getUserMedia(audioConstraints)
      .then(async (stream) => {
        let tracks;
        // create data track for messages
        const audioTrack = new LocalAudioTrack(stream.getAudioTracks()[0]);
        const dataTrack = new LocalDataTrack();
        tracks = [audioTrack, dataTrack];
        const room = await connect(accessToken, {
          name: currentRoomID,
          tracks,
        });
        this.setRoom(room);
      })
      .catch((err) => {
        
      });
  };
}
