import React, { useState, useEffect, useRef } from "react";
import { getLocalState } from "../../studio_store_medical_components/src/Utility.web";
import { User } from "./assets";

const AudioParticipant = (props: { location?: any; participant?: any }) => {
  const { participant } = props;
  const [videoTracks, setVideoTracks] = useState<any[]>([]);
  const [audioTracks, setAudioTracks] = useState<any[]>([]);
  const [participantProfile, setParticipantProfile] = useState<any>(null);
  const userData = getLocalState("userData");

  const videoRef = useRef() as React.MutableRefObject<HTMLVideoElement>;
  const audioRef = useRef() as React.MutableRefObject<HTMLAudioElement>;

  const trackPubsToTracks = (trackMap: any[]) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);
      

  useEffect(() => {
    setVideoTracks(trackPubsToTracks(participant.videoTracks));
    setAudioTracks(trackPubsToTracks(participant.audioTracks));

    const trackSubscribed = (track: { kind: string }) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: { kind: string }) => {
      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
    if (userData?.attributes?.role === "DOCTOR") {
      const { doctorDetails } = props?.location?.state;
      setParticipantProfile(doctorDetails);
    }
    if (userData?.attributes?.role !== "DOCTOR") {
      const { patientDetails } = props?.location?.state;
      setParticipantProfile(patientDetails);
    }
  }, [props]);

  return (
    <div>
      <div className="">
        <img
          className="audio-img"
          src={participantProfile?.profile_pic ?? User}
        />
        <h3 className="participant-text">
          {participantProfile?.full_name ?? participant.identity}
        </h3>
        <audio ref={audioRef} autoPlay={true} muted={false} />
      </div>
    </div>
  );
};

export default AudioParticipant;
export { AudioParticipant };
