import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyC5j-w-ujQxe5xYJMWAe9rsqfZ5-QYolJ8",
  authDomain: "medicalapp-ad4f1.firebaseapp.com",
  projectId: "medicalapp-ad4f1",
  storageBucket: "medicalapp-ad4f1.appspot.com",
  messagingSenderId: "188329558629",
  appId: "1:188329558629:web:3ed6de43b408eb1f0a7c8c",
  measurementId: "G-MPK5JPP695"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const getTokenData = () => {
  return getToken(messaging).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      localStorage.setItem('fcmToken', currentToken.toString())
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});