// App.js - WEB
import React, { Component, lazy } from "react";
// import { initializeApp } from "firebase/app";

// @ts-ignore
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../../../components/src/HomeScreen";
// @ts-ignore
import { onMessageListener, getTokenData } from './firebaseInit.js';

const configJson = require('../../../../framework/src/config');


if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: "HomeScreen",
  };
  const homeScreen = new HomeScreen(defaultProps);
}

import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Routes from ".";
import { setLocalState } from "../../../studio_store_medical_components/src/Utility.web";

class App extends Component {
  async componentDidMount() {
    getTokenData();
    this.getBrandSettings()
  }


  /**
   * Get brands settings
   */
  getBrandSettings = () => {
    const url =
      `${configJson.baseURL}/bx_block_store/brand_settings/get_settings`;
    fetch(url)
      .then(response => response.json())
      .then(res => {
        if (res?.brand_setting) {
          setLocalState(
            'brandSetting',
            JSON.stringify(res?.brand_setting)
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    onMessageListener().then((payload: any) => {
      toast.success(payload.notification.body)
    }).catch((err: any) => '');

    return (
      <>
        <ModalContainer />
        <Routes />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  }
}

export default App;
