Object.defineProperty(exports, "__esModule", {
  value: true,
});
// Customizable Area Start

/* API Constants */
exports.contentType = "application/json";

/* APIs Method list */
exports.apiPostMethod = "POST";
exports.apiGetMethod = "GET";

/* Call APIs */
exports.videoCallStartApiEndpoint =
  "bx_block_audiocall/twilios/setup_room?patient_booking_id=";
exports.audioCallAccessTokeApiEndPoint =
  "bx_block_audiocall/twilios/setup_room";

/* Prescription APIs */
exports.noPrescriptionApiEndpoint =
  "bx_block_appointment_management/patient_bookings/update_appointment_completion_status";

// Customizable Area End
