import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import "./FooterWeb.scss";
import { Link } from "react-router-dom";
export const FacebookIcon = require("./images/facebook_icon.svg");
// export const GoogleIcon = require("./images/google_icon.svg");
export const InstagramIcon = require("./images/instagram_icon.svg");
export const TwitterIcon = require("./images/twitter_icon.svg");
export const YoutubeIcon = require("./images/youtube_icon.svg");
export const Promotion = require("./images/promotions.jpg");

const WebFooter = () => {
    // @ts-ignore
    const brandSetting = localStorage.getItem("brandSetting") !== "undefined" ? JSON.parse(localStorage.getItem("brandSetting")) : {};

    const [showTapToTop, setShowTapToTop] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setShowTapToTop(true);
            } else {
                setShowTapToTop(false)
            }
        })
    }, [])

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <>
            <footer className="footer">
                <Container>
                    <Row>
                        <Col md={5}>
                            <div className="footer_item about">
                                <h1>{brandSetting?.ExtraFields?.heading}</h1>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br className="d-none d-md-block" /> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p> */}
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <a href={brandSetting?.footerContent?.facebookSrc} target="_blank">
                                            <img src={FacebookIcon} alt="Facebook Icon" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={brandSetting?.footerContent?.youtube_link} target="_blank">
                                            <img src={YoutubeIcon} alt="Youtube Icon" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={brandSetting?.footerContent?.instagramSrc} target="_blank">
                                            <img src={InstagramIcon} alt="Instagram Icon" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={brandSetting?.footerContent?.twitterSrc} target="_blank">
                                            <img src={TwitterIcon} alt="Twitter Icon" className="img-fluid" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={2} sm={4}>
                            <div className="footer_item links">
                                <h3>Useful Links</h3>
                                <ul className="list-unstyled">
                                    <li><Link to="/about-us">About Us</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li><a href="/help-center">Help Center</a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={2} sm={4}>
                            <div className="footer_item links">
                                <h3>Quick Links</h3>
                                <ul className="list-unstyled">
                                    <li><a href="/help-center">FAQ's</a></li>
                                    <li><a href="/help-center">Terms & Conditions</a></li>
                                    <li><a href="/help-center">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={3} sm={4}>
                            <div className="footer_item promotions">
                                <h3>Promotions</h3>
                                <img src={brandSetting?.footerContent?.promotionBannerSrc ? brandSetting?.footerContent?.promotionBannerSrc : Promotion} alt="Promotion" className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="copyright mt-5">
                <p>{brandSetting?.footerContent?.copyright ? brandSetting?.footerContent?.copyright : "-"}</p>
            </div>
            </footer>

            
            {showTapToTop &&
                <div className="tap-to-top" >
                    <Button color="#fff" className='p-2 ' onClick={scrollUp}>
                        <i className="fa fa-arrow-up" aria-hidden="true"></i>
                    </Button>
                </div>}
        </>
    )
}

export default WebFooter;