import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getLocalState } from "../../studio_store_medical_components/src/Utility.web";
import AudioParticipant from "./AudioParticipant.web";
import AudioParticipant2 from "./AudioParticipent2.web";
import { WaitingLoader } from "../../VideoCall5/src/VideoSection/TwilioRoom/WaitingLoader.web";
import { toast } from "react-toastify";

const AudioRoom = (props: any) => {
  const { room, formatTime } = props;
  const [time, setTime] = useState(0);
  const [participants, setParticipants] = useState<any[]>([]);
  const userData = getLocalState("userData");

  let timerId:any;
  const personJoined = () => {
   timerId = setInterval(() => {
      setTime((t) => t + 1);
    }, 1000);
    return () => clearInterval(timerId);

  };

  const participantConnected = (participant: any) => {
    setParticipants((prevParticipants) => [...prevParticipants, participant]);
    if(userData?.attributes.role !== "DOCTOR" || timerId > 0){
      clearInterval(timerId);
    }
    personJoined();
  };
  

  const participantDisconnected = (participant: any) => {
    if (userData?.attributes.role !== "DOCTOR") {
      toast.error("Doctor Ended Call");
      setTimeout(() => {
        window.location.href = "/user-profile";
      }, 2000);
    }
    setParticipants((prevParticipants) =>
      prevParticipants.filter((p) => p !== participant)
    );
  };

  const onparticipantConnected = () => {
    room.on("participantConnected", participantConnected);
  };
  const onparticipantDisconnected = () => {
    room.on("participantDisconnected", participantDisconnected);
  };

  const offparticipantConnected = () => {
    room.off("participantConnected", participantConnected);
  };
  const offparticipantDisconnected = () => {
    room.off("participantDisconnected", participantDisconnected);
  };

  const participantsForEach = () => {
    room.participants.forEach(participantConnected);
  }

  useEffect(() => {
    onparticipantConnected();
    onparticipantDisconnected();
    participantsForEach();
    return () => {
      offparticipantConnected();
      offparticipantDisconnected();
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <AudioParticipant2
      key={participant.sid}
      participant={participant}
      {...props}
    />
  ));

  const waitingDiv = userData?.attributes?.role === "DOCTOR" ? (
    <span>Waiting for the Patient to join...</span>
  ) : (
    <span>Waiting for the Physician to join...</span>
  )

  const participantsDiv =  participants?.length > 0 ? (
    <>
      <div className="audio-call-timer">{`${formatTime(time)}`}</div>
      {remoteParticipants}
    </>
  ) : (
    <div className="waiting-loader">
      <div className="">
        <WaitingLoader />
      </div>
      <div className="">
        {waitingDiv}
      </div>
    </div>
  )

  return (
    <div className="room">
      <div className="audio-participant">
        <AudioParticipant
          key={room.localParticipant.sid}
          participant={room.localParticipant}
          {...props}
        />
        {participantsDiv}
      </div>
    </div>
  );
};

export default withRouter(AudioRoom);
export { AudioRoom };
